export const REACT_APP_LSMAT_API_URL: string = (process.env.REACT_APP_LSMAT_API_URL as string)


export class ExamTask{
    exam_section_name: string = '';
    name: string = '';
    max_points: number = 0;
    solution: string = '';

    points: number = 0;
};

export class ExamSection{
    exam_name: string = '';
    name: string = '';
    score_weight: number = 1;
    secret: string = '';   
    
    exam_tasks: ExamTask[] = [];
};

export class Exam{
    name: string = '';
    event_name: string = '';
    secret: string = '';    
    points: number = 0;
    max_points: number = 0;

    exam_sections: ExamSection[] = [];
};

export class TaskGrade{
    name: string = '';
    graded_page_secret: string = '';
    ungraded_pages: number = 0;
};

export class ExamSubmitter{
    name: string = '';
    exam_name: string = '';
    email_address: string = '';
    rsvp_option_name: string = '';
    secret: string = '';    
};

export class ExamSubmissionFile { // TODO: get from common SwaggerFile
    filename: string = '';
    submission_secret: string = '';
    file;
    rotation: number = 0;
    secret: string = '';

    times_graded: number = 0;
};

export class SubmissionDetails{
    name: string = '';
    gmt0_submitted: string = '';
    exam_submitter_secret: string = '';
    secret: string = '';

    submission_files: ExamSubmissionFile[] = [];
};

export class Judge {
    name: string = '';
    email_address: string = '';
    secret: string = '';
    
    ungraded_pages: number = 0;
    graded_pages: number = 0;
};

export class GradedPage {
    secret: string = '';
    exam_code: string = '';
    judge_secret: string = '';
    submission_file_secret: string = '';
    exam_section: ExamSection | null = null;
    comment: string = '';
    
    exam_secret: string = '';

    assign_to_self: boolean = true;
}

export class ResultStats {
    section1_points: number = 0;
    section2_points: number = 0;
    section3_points: number = 0;
}

export interface RsvpOption {
    id: number
    name: string
    affirmative: boolean
    limit: number
    description: string
    image: ArrayBuffer
    wgs84_lat: number
    wgs84_lon: number
    
    applied: number
    
    selected: boolean
}

export interface RsvpOptions {
    event_name: string
    rsvp_options: RsvpOption[]
};

export function getFileUrl(
    file_secret: string,
): string {
    return `${REACT_APP_LSMAT_API_URL}/files/${file_secret}`
}

export async function getExamResultStats(
    rsvp_secret: string,
): Promise<ResultStats> {

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/result/${rsvp_secret}/stats`, 
        {
            method: 'GET',
        }
    )

    let response: Response; // TODO: make boilerplate
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: ResultStats = await response!.json();

    return result;
};


export async function getExamResult(
    rsvp_secret: string,
): Promise<Exam> {

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/result/${rsvp_secret}/all`, 
        {
            method: 'GET',
        }
    )

    let response: Response; // TODO: make boilerplate
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: Exam = await response!.json();

    return result;
};

export async function getPageDetails(
    judge_secret: string,
    page_secret: string,
): Promise<GradedPage> {

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/result/${judge_secret}/page/${page_secret}`, 
        {
            method: 'GET',
        }
    )

    let response: Response; // TODO: make boilerplate
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: GradedPage = await response!.json();

    return result;
};

export async function getAllResultPages(
    rsvp_secret: string,
): Promise<GradedPage[]> {

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/result/${rsvp_secret}/all`, 
        {
            method: 'GET',
        }
    )

    let response: Response; // TODO: make boilerplate
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: GradedPage[] = await response!.json();

    return result;
};