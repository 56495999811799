import React, { useEffect, useState } from 'react';
import { Trans } from "@lingui/macro";
import { useParams } from 'react-router-dom'
import './Rsvp.css';

import ActionButton from './ActionButton'

const REACT_APP_LSMAT_API_URL: string = (process.env.REACT_APP_LSMAT_API_URL as string)  // TODO: pull from env

interface RsvpOption {
    id: number
    name: string
    affirmative: boolean
    limit: number
    description: string
    image: ArrayBuffer
    wgs84_lat: number
    wgs84_lon: number
    
    applied: number
    
    selected: boolean
}

interface RsvpOptions {
    event_name: string
    rsvp_options: RsvpOption[]
}

async function getRsvpOptions(
        secret
    ): Promise<RsvpOptions> {

    const request: RequestInfo = new Request(
        REACT_APP_LSMAT_API_URL + '/rsvp/options/get' + '?' + 'secret=' + secret, 
        {
            method: 'GET'
        }
    )

    const response: Response = await fetch(request)
    const result: RsvpOptions = await response.json()

    return result
}

function OptionConditionalButton({ rsvp_option, secret }) {
    function setRsvpResponse() {
    
        const request: RequestInfo = new Request(
            REACT_APP_LSMAT_API_URL + '/rsvp/response/set' + '?' + 'secret=' + secret + '&' + 'option_id=' + rsvp_option.id, 
            {
                method: 'POST'
            }
        )
    
        fetch(request)
            .then(res => {
                console.log("got response:", res)
        })

        window.location.reload(); // TODO: find a better way
    }

    if (rsvp_option.selected) {
        return <button className='SelectButton' disabled={true}>
                <Trans>Atlasīts</Trans>
            </button>
    }

    if ( (rsvp_option.limit != rsvp_option.applied) || (rsvp_option.limit == null)) {
        console.log(rsvp_option.selected)
        return <button className='SelectButton' onClick={setRsvpResponse}>
                <Trans>Izvēlēties</Trans>
            </button>
    }
    return <div></div>
}

function AffirmativeOptionCard({ rsvp_option, secret }) { // TODO: cleaup props
    return (
        <div className='AffirmativeOptionCard'>
            <div className='OptionInfo'>
                <div className='OptionName'>
                    <h2>{rsvp_option.name}</h2>
                    <p className='OptionDescription'>{rsvp_option.description}</p>
                </div>
                <div className='OptionProgress'>
                    <progress value={rsvp_option.applied / rsvp_option.limit}/>
                    <p>{rsvp_option.applied} / {rsvp_option.limit} <Trans>vietas aizņemtas</Trans></p>
                </div>
            </div>
            <div className='OptionAction'>
                <OptionConditionalButton rsvp_option={rsvp_option} secret={secret} />
            </div>
            </div>
    )
}

function NegatvieOptionCard({ rsvp_option, secret }) {
    return (
        <div className='NegatvieOptionCard'>
            <div className='OptionInfo'>
                <h2>{rsvp_option.name}</h2>
            </div>
            <div className='OptionAction'>
                <OptionConditionalButton rsvp_option={rsvp_option} secret={secret} />
            </div>
        </div>
    )
}

function Rsvp() {
    const { secret } = useParams()
    const [ rsvpOptions, setRsvpOptions ] = useState<RsvpOptions | null>(null);

    useEffect(() => {
        getRsvpOptions(secret).then(res => {
            setRsvpOptions(res)
        })
    }, [])
    return (
        <div className='RsvpPage'>
            <h1 className='EventTitle'>{rsvpOptions?.event_name}</h1>
            <div className='RsvpOptions'>
                <>
                    {rsvpOptions?.rsvp_options.map( rsvp_option => (
                        <div className='RsvpOption'>
                            {rsvp_option.affirmative
                                ? <AffirmativeOptionCard rsvp_option={rsvp_option} secret={secret}/>
                                : <NegatvieOptionCard rsvp_option={rsvp_option} secret={secret}/>
                            }
                        </div>
                    ))}
                </>
            </div>
        </div>
    );
}

export default Rsvp;