import React from 'react';
import { Trans, t } from "@lingui/macro";
import './Page2024.css';

function Page2024() {
  return (
    <div className='PrepContainer'>
        <Trans><h2>Atlase</h2></Trans>
        <div className='Formulae'>
          <Trans><p>4. decembrī veiksmīgi novadīta jau otrā Latvijas skolēnu matemātiskās analīzes turnīra atlase: 88 skolēni pildīja uzdevumus 4 norises vietās. Uz finālu tiek uzaicināti skolēni ar<b>22 vai vairāk punktiem.</b></p></Trans>
          <div className='FormulaeTables'>
            <img src='/LSMAT2024/atlase_izv_1.JPG' alt='atlase izv 1' />
            <img src='/LSMAT2024/atlase_rv1g_1.JPG' alt='atlase rv1g 1' />
            <img src='/LSMAT2024/atlase_lvt_1.jpeg' alt='atlase lvt 1' />
          </div>
        </div>
        <div className='SelectionStats'>
          <img 
            className='HistogramPic'
            src='/LSMAT2024/atlase_histogram.png' 
            alt='atlases punktu sadalījums' />
        </div>
        <ul className='DocList'>
          <li><Trans>1. daļa - atvasināšanas uzdevumi:</Trans>
            <Trans><a href='/LSMAT2024/LSMAT2024_Atlases_atvasinsanas_uzdevumi.pdf'>uzdevumi</a>|<a href='/LSMAT2024/LSMAT2024_Atlases_atvasinsanas_atbildes.pdf'>atbildes</a></Trans>
            </li>
          <li><Trans>2. daļa - integrēšanas uzdevumi:</Trans>
            <Trans><a href='/LSMAT2024/LSMAT2024_Atlases_integresanas_uzdevumi.pdf'>uzdevumi</a>|<a href='/LSMAT2024/LSMAT2024_Atlases_integresanas_atbildes.pdf'>atbildes</a></Trans>
            </li>
          <li><Trans>3. daļa - paaugstinātas grūtības uzdevumi:</Trans>
            <Trans><a href='/LSMAT2024/LSMAT2024_Atlases_paaugstinatas_grutibas_uzdevumi.pdf'>uzdevumi</a>|<a href='/LSMAT2024/LSMAT2024_Atlases_paaugstinatas_grutibas_atbildes.pdf'>atbildes</a></Trans>
            </li>
        </ul>
    </div>
  );
}

export default Page2024;