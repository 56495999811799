import React, { useEffect, useState } from 'react';
import { Trans } from "@lingui/macro";
import { useParams } from 'react-router-dom'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  GradedPage,
  getAllResultPages,
  ResultStats,
  getExamResultStats,
  REACT_APP_LSMAT_API_URL,
} from './api'

import './Grade.css';

const defaultData: GradedPage[] = []

const columnHelper = createColumnHelper<GradedPage>()

function ScoreCard( {points, max_points, text} ) {
    return (
        <div className='ScoreCard'>
            <p>
                {Math.round(points * 10) / 10} / {max_points} punkti
            </p>
            {text}
        </div>
    )
}

function Grade() {
  const { id } = useParams()
  const [resultStats, setResultStats] = useState<ResultStats>(new ResultStats());
  const [data, setData] = React.useState(() => [...defaultData])
  
  const columns = [
    columnHelper.accessor('secret', {
      header: () => <span><Trans>Izlabotais darbs</Trans></span>,
      cell: secret => <a
       href={`/result/${id}/page/${secret.getValue()}`}
      ><Trans>Apskatīt vērtējumu</Trans></a>,
    }),
    columnHelper.accessor('exam_code', {
      header: () => <span><Trans>Dalībnieka kods</Trans></span>,
    }),
    columnHelper.accessor('submission_file_secret', {
      header: () => <span><Trans>Iesniegtais fails</Trans></span>,
      cell: secret => <a 
        href={`${REACT_APP_LSMAT_API_URL}/files/${secret.getValue()}`}
        target="_blank"
      ><Trans>Atvērt</Trans></a>,
    }),
    columnHelper.accessor('exam_section', {
      header: () => <span><Trans>Sadaļa</Trans></span>,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    console.log(id)
    getAllResultPages(id!).then(res => {
      setData(res);
      });
    getExamResultStats(id!).then(res => {
        setResultStats(res);
    });
  }, [])

  return (
    <div className='Grade'>
        <h1><Trans>Tavs atlases kārtas rezultāts</Trans></h1>
        <div className='AllScore'>
            <ScoreCard points={resultStats.section1_points+resultStats.section2_points+resultStats.section3_points} max_points={64} text={`Kopā`} />
            <div className='ScoreCards'>
                <ScoreCard points={resultStats.section1_points} max_points={20} text={`1. daļā`} />
                <ScoreCard points={resultStats.section2_points} max_points={20} text={`2. daļā`} />
                <ScoreCard points={resultStats.section3_points} max_points={26} text={`3. daļā`} />
            </div>
        </div>
        <div className='TopGradingSection'>
            <div>
            <p>Zemāk vari apskatīt savu darbu un tā vērtējumus. Daļa darbu ir vērtēti vairākas reizes, tādēļ ir redzami atkārtoti ieraksti.</p>
            <a className='SocialLabel' href="mailto:apelacijas@analizesturnirs.lv">apelacijas@analizesturnirs.lv</a>
            </div>
        </div>
        <div className='TableContainer'>
            <table className='JudgedPagesTable'>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
    </div>
  );
}

export default Grade;