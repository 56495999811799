import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Trans } from "@lingui/macro";
import './App.css';
import Landing from './Landing'
import Layout from './layout/Layout'
import Page2024 from './Page2024';
import Prep from './Prep';
import About from './About';
import Rsvp from './Rsvp';
import Result from './Result';
import ResultPage from './ResultPage';

const sections = [ // TODO: add localisation for menu entries
  {name: 'Turnīrs', href: '/'},
  {name: 'Atlase', href: '/page2024'},
  {name: 'Sagatavošanās', href: '/prep'},
  //{name: 'Iepriekšējie gadi', href: '/previous'},
  {name: 'Par mums', href: '/about'},
]

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout sections={sections}/>}>
            <Route index element={<Landing/>} />
            <Route path='page2024' element={<Page2024/>} />
            <Route path='prep' element={<Prep/>} />
            <Route path='about' element={<About/>} />
            <Route path='/rsvp/:secret' element={<Rsvp/>} />
            <Route path="/result/:id" >
              <Route index element={<Result/>} />
              <Route path='page/:result_page_secret/' element={<ResultPage/>} />
            </Route>
          </Route>
          <Route path='/signup' Component={() => {
              window.location.href = 'https://forms.gle/68xUBdgUDVmqhFWH6';
              return null;
          }}/>
          <Route path='/course-signup' Component={() => {
              window.location.href = 'https://forms.gle/Q1pLLVfeZktxNquG8';
              return null;
          }}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
