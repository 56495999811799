import React from 'react';
import { Trans, t } from "@lingui/macro";
import './Prep.css';

function Prep() {
  return (
    <div className='PrepContainer'>
        <Trans><h2>Formulas</h2></Trans>
        <div className='Formulae'>
          <Trans><p>Turnīrā izmantot formulu lapas netiks atļauts, tādēļ sekojošo vajadzētu zināt no galvas:</p></Trans>
          <div className='FormulaeTables'>
            <img src='/deriv_rules.png' alt='derivative rules' />
            <img src='/deriv_table.png' alt='derivative table' />
            <img src='/integr_table.png' alt='integral table' />
          </div>
        </div>

        <Trans><h2>Formulu lapas</h2></Trans>
        <ul className='DocList'>
          <li>
            <Trans><a href='/kursi2024/integresanas_formulu_lapa.pdf'>Integrēšanas</a></Trans>
            </li>
          <li>
            <Trans><a href='/kursi2024/atvasinasanas_formulu_lapa.pdf'>Atvasināšanas</a></Trans>
            </li>
        </ul>

        <Trans><h2>Treniņa uzdevumi</h2></Trans>
        <Trans><p>
          2024. gada turnīrs:
          </p></Trans>
        <ul className='DocList'>
          <li><Trans>1. daļa - atvasināšanas uzdevumi:</Trans>
            <Trans><a href='/LSMAT2024/LSMAT2024_Atlases_atvasinsanas_uzdevumi.pdf'>uzdevumi</a>|<a href='/LSMAT2024/LSMAT2024_Atlases_atvasinsanas_atbildes.pdf'>atbildes</a></Trans>
            </li>
          <li><Trans>2. daļa - integrēšanas uzdevumi:</Trans>
            <Trans><a href='/LSMAT2024/LSMAT2024_Atlases_integresanas_uzdevumi.pdf'>uzdevumi</a>|<a href='/LSMAT2024/LSMAT2024_Atlases_integresanas_atbildes.pdf'>atbildes</a></Trans>
            </li>
          <li><Trans>3. daļa - paaugstinātas grūtības uzdevumi:</Trans>
            <Trans><a href='/LSMAT2024/LSMAT2024_Atlases_paaugstinatas_grutibas_uzdevumi.pdf'>uzdevumi</a>|<a href='/LSMAT2024/LSMAT2024_Atlases_paaugstinatas_grutibas_atbildes.pdf'>atbildes</a></Trans>
            </li>
        </ul>

        <Trans><p>
          2023. gada turnīrs:
          </p></Trans>
        <ul className='DocList'>
          <li><Trans>Atlases atvasināšanas uzdevumi:</Trans>
            <Trans><a href='/LSMAT2023/LSMAT2023_Atlases_atvasinsanas_uzdevumi.pdf'>bez atbildēm</a></Trans>|<Trans><a href='/LSMAT2023/LSMAT2023_Atlases_atvasinsanas_atbildes.pdf'>ar atbildēm</a></Trans>
            </li>
          <li><Trans>Atlases integrēšanas uzdevumi:</Trans>
            <Trans><a href='/LSMAT2023/LSMAT2023_Atlases_integresanas_uzdevumi.pdf'>bez atbildēm</a></Trans>|<Trans><a href='/LSMAT2023/LSMAT2023_Atlases_integresanas_atbildes.pdf'>ar atbildēm</a></Trans>
            </li>
          <li><Trans>Atlases otrā posma uzdevumi:</Trans>
            <Trans><a href='/LSMAT2023/LSMAT2023_Atlases_otra_posma_uzdevumi.pdf'>bez atbildēm</a></Trans>
            </li>
        </ul>

        <Trans><h2>2024. gada kursu materiāli</h2></Trans>
        <Trans><p>
          2024. gada matemātiskās analīzes ievada kursu:
          </p></Trans>
        <ul className='DocList'>
          <li><Trans>1. nodarbība - ievads un pamattēmu atkārtojums: </Trans>
            <Trans><a href='/kursi2024/lsmat_kursi_nodarbiba1.pdf'>apkopojums</a></Trans>
            </li>
          <li><Trans>2. nodarbība - atvasinājums: </Trans>
            <Trans><a href='/kursi2024/lsmat_kursi_nodarbiba2.pdf'>apkopojums</a></Trans>
            </li>
          <li><Trans>3. nodarbība - integrālis: </Trans>
            <Trans><a href='/kursi2024/lsmat_kursi_nodarbiba3.pdf'>apkopojums</a></Trans>
            </li>
          <li><Trans>4. nodarbība - racionālo funkciju un substitūciju metodes integrēšana: </Trans>
            <Trans><a href='/kursi2024/lsmat_kursi_nodarbiba4.pdf'>apkopojums</a></Trans>
            </li>
          <li><Trans>5. nodarbība - diferenciālvienādojumi: </Trans>
            <Trans><a href='/kursi2024/lsmat_kursi_nodarbiba5.pdf'>apkopojums</a></Trans>
            </li>
        </ul>

        <Trans><h2>Sagatavošanās materiāli</h2></Trans>
        <div className='PrepMaterial'>
          <Trans><p>
          Šogad skolēniem ir unikāla iespēja apmeklēt bezmaksas matemātiskās analīzes ievad kursus, lai turnīram sagatavotos pat bez priekšzināšanām. Kursi notiks 5 nedēļas nogales: 3., 10., 17., 23. novembrī un 1. decembrī no rītiem, Rīgas Valsts 1. ģimnāzijas telpās. Programma tiek īstenota sadarbībā ar Rīgas Domi. Kursos tiks apskatītas galvenās pamata matemātiskās analīzes tēmas no atvasināšanas līdz integrēšanai. Kursus pasniegs iepriekšējā gada LSMAT uzvarētājs Pēteris Skrastiņš pamatojoties uz Iljas Nika Stoligvo un Nila Žuravļova sagatavotajiem materiāliem. 
          </p></Trans>
          <Trans><p>
          Vispārīgā teorija ir pieejama zemāk, bet ar katru nedēļu papildus tiks pievienoti konspekti no sagatavošanas kursiem.
          </p></Trans>
          <Trans><a href = '/LSMAT_Teorija.pdf' target = "_blank">Sagatavošanās materiāli</a></Trans>
          <embed src="/LSMAT_Teorija.pdf" className='PDFViewer'/>
        </div>
    </div>
  );
}

export default Prep;